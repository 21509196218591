import Swal from 'sweetalert2';
import withComponent from 'sweetalert2-react-content';

const mySwal = withComponent(Swal);

export const successNotification = (title: string, message: string = 'Sucesso!') => {
	mySwal.fire({
		icon: 'success',
		title,
		text: message,
	});
};

export const errorNotification = (title: string, message: string = 'Erro!') => {
	mySwal.fire({
		icon: 'error',
		title,
		text: message,
	});
};

export const infoNotification = (title: string, message: string) => {
	mySwal.fire({
		icon: 'info',
		title,
		text: message,
	});
};

export const warningNotification = (title: string, message: string) => {
	mySwal.fire({
		icon: 'warning',
		title,
		text: message,
	});
};

export const questionNotification = (
	title: string,
	message: string,
	confirmButtonColor: string,
	confirmButtonText: string,
	cancelButtonText: string,
	functionConfirm: { (): Promise<void>; (): void }
) => {
	mySwal
		.fire({
			icon: 'question',
			title,
			text: message,
			confirmButtonColor: confirmButtonColor,
			showCancelButton: true,
			cancelButtonColor: '#86adbc',
			confirmButtonText,
			cancelButtonText,
		})
		.then((result) => {
			if (result.isConfirmed) {
				functionConfirm();
			}
		});
};
