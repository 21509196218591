import React, { createContext, useState, useEffect, SetStateAction } from 'react';
import Cookie from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

const Context = createContext<ContextType>({} as ContextType);

type ContextType = {
	authenticated: boolean;
	loading: boolean;
	companies: any;
	auth: any;
	authorizedPages: any;
	setCompanies: React.Dispatch<SetStateAction<any>>;
	setAuth: React.Dispatch<SetStateAction<any>>;
	setAuthenticated: React.Dispatch<SetStateAction<boolean>>;
	logout: React.Dispatch<SetStateAction<any>>;
};

type AuthProviderType = {
	children: React.ReactChild;
};

function AuthProvider({ children }: AuthProviderType) {
	const [authenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [auth, setAuth] = useState([]);
	const [tokenJwt, setTokenJwt] = useState('');
	const [authorizedPages, setAuthorizedPages] = useState([
		'mensagenspredefinidas',
		'dispositivos',
		'caixadeentrada',
		'gerenciaragrupamentosdegrupos',
		'contatos',
		'atendentes',
		'setoresdeatendimento',
	]);

	const cookieToken = Cookie.get('jwtAuth');
	const history = useHistory();

	// Validate Token to login or redirect to /login
	const validateToken = async () => {
		if (!cookieToken) {
			setAuthenticated(false);
			setLoading(false);
			return;
		} else {
			setAuthenticated(true);
			setLoading(false);

			// api.defaults.headers.common['Authorization'] = `Bearer ${cookieToken}`
		}

		// try {
		//   console.log(cookieToken)
		//   const response = await api.get()

		//   console.log(response)

		//   switch (response.data) {
		//     case 'success':
		//       setAuthenticated(true)
		//       setLoading(false)
		//       break
		//     case 'fail':
		//       setAuthenticated(false)
		//       setLoading(false)
		//       break
		//   }
		// } catch (error) {
		//   console.log(error)
		// }
	};

	// Get a validateToken function every time a page reload
	// useEffect(() => {
	//   validateToken()
	// }, [])

	// Logout function
	const logout = async () => {
		Cookie.remove('jwtAuth');
		Cookie.remove('session');
		Cookie.remove('userName');
		localStorage.clear();
		// api.defaults.headers.common['Authorization'] = ``
		history.push('/login');
	};

	return (
		<Context.Provider
			value={{
				authenticated,
				setAuthenticated,
				loading,
				logout,
				companies,
				setCompanies,
				authorizedPages,
				setAuth,
				auth,
			}}>
			{children}
		</Context.Provider>
	);
}

export { AuthProvider, Context };
