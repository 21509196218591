import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import Cookie from 'js-cookie';
import axios from 'axios';

import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';

import { Context } from '../../../contexts/AuthContext';

import './styles.scss';

import { providerSystem } from '../../../utils/providerAssets';

function CreateAccountPage() {
	document.title = providerSystem()?.name + ' - Registro';

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [company, setCompany] = useState('');
	const [token, setToken] = useState('');

	const [verifyTokenResponse, setVerifyTokenResponse] = useState<any>({});
	const [registerPageLoading, setRegisterPageLoading] = useState(true);
	const [registerButtonClicked, setRegisterButtonClicked] = useState(false);

	const [registerError, setRegisterError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const { key } = useParams<{ key: string }>();
	const history = useHistory();

	const { setCompanies, setAuthenticated } = useContext(Context);

	// Handle Register
	const handleRegister = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setRegisterButtonClicked(true);

		if (password != confirmPassword) {
			const passwordInput = document.getElementById('passwordInput');
			const confirmPasswordInput = document.getElementById('confirmPasswordInput');

			// Add error
			passwordInput?.classList.add('fieldError');
			confirmPasswordInput?.classList.add('fieldError');
			setPasswordError('As senhas não coincidem!');

			// Remove error
			passwordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			confirmPasswordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			return;
		}

		if (password.length < 6) {
			const passwordInput = document.getElementById('passwordInput');
			const confirmPasswordInput = document.getElementById('confirmPasswordInput');

			// Add error
			passwordInput?.classList.add('fieldError');
			confirmPasswordInput?.classList.add('fieldError');
			setPasswordError('A senha deve conter no mínimo 6 dígitos!');

			// Remove error
			passwordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			confirmPasswordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			return;
		}

		// Register function
		registerFunction(name, company, email, password, confirmPassword, token);
	};

	// Set Authenticated before go to Dashboard
	const handleGoToDashboard = (token: string) => {
		setAuthenticated(true);
		Cookie.set('jwtAuth', token, { expires: 30, secure: true, sameSite: 'None' });

		window.location.href = '/';
	};

	// Register Function
	const registerFunction = async (name: string, company: string, email: string, password: string, confirmPassword: string, token: string) => {
		if (password !== confirmPassword) {
			setRegisterError('As senhas não coincidem!');
			setRegisterButtonClicked(true);
			return;
		}

		const response = await axios.post('/ts-api/auth/register-company-and-user', {
			token: token,
			user: {
				name: name,
				email: email,
				password: password,
			},
			company: {
				name: company,
				email: email,
			},
		});

		switch (response.data.status) {
			case 'success':
				const { tokenJWT } = response.data;

				const userInformations = {
					userName: name,
					companyName: company,
				};

				localStorage.setItem('userInformations', JSON.stringify(userInformations));

				handleGoToDashboard(tokenJWT);
				break;

			default:
				setRegisterError(response.data.message);
				setRegisterButtonClicked(false);
				break;
		}
	};

	// Get token verification
	useEffect(() => {
		const handleVerifyToken = async () => {
			const responseVerifyToken = await axios.get(`/ts-api/auth/verify-token-create-company/${key}`);
			setVerifyTokenResponse(responseVerifyToken.data);

			if (key != undefined) {
				setToken(key);
			}

			// Set loading false
			setRegisterPageLoading(false);
		};

		handleVerifyToken();
	}, []);

	// Set Background Classlist
	useEffect(() => {
		document.body.classList.add('gray-100');
		document.body.classList.add('h-100');
	}, []);

	// Set Name and Email if VerifyTokenResponse modify
	useEffect(() => {
		// Set email and name into state
		if (verifyTokenResponse.status == 'success') {
			setEmail(verifyTokenResponse.email);
		}
	}, [verifyTokenResponse]);

	// If page loading
	if (registerPageLoading) {
		return (
			<Container className="bg-gray-100 h-100">
				<Row className="bg-gray-100 align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card>
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem() + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Crie sua conta</div>
								</div>

								<p className="font-weight-bold text-center mb--1" style={{ fontSize: '18px' }}>
									Carregando <i className="fa fa-circle-notch fa-spin" />
								</p>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<>
			<Container className="h-100">
				<Row className="align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card>
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem()?.assets + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Crie sua conta</div>
								</div>

								{verifyTokenResponse.status != 'success' || registerError ? (
									// If Token error
									<form>
										<Row>
											<Col lg="12" sm="12">
												<div className="divFormInput">
													<p className="text-muted font-weight-bold text-center">
														{verifyTokenResponse.message || registerError}
													</p>
												</div>
											</Col>
										</Row>
									</form>
								) : (
									// If Token is correct
									<form onSubmit={handleRegister}>
										<Row>
											<Col lg="12" sm="12">
												<div className="divFormInput mb-4">
													<Input
														name="company"
														className="input"
														onChange={(e) => setCompany(e.target.value)}
														autoComplete="company"
														required
													/>
													<label className="text-muted">Nome da sua empresa</label>
												</div>
												<div className="divFormInput mb-4">
													<Input name="name" value={name || ''} onChange={(e) => setName(e.target.value)} required />
													<label className="text-muted">Seu nome</label>
												</div>
												<div className="divFormInput mb-4">
													<Input
														name="email"
														type="email"
														onChange={(e) => setEmail(e.target.value)}
														value={email || ''}
														required
														disabled
													/>
													<label className="text-muted">Seu e-mail</label>
												</div>

												<div className="divFormInput mb-4">
													<Input
														id="passwordInput"
														name="password"
														type="password"
														autoComplete="new-password"
														onChange={(e) => setPassword(e.target.value)}
														required
													/>
													<label className="text-muted">Sua senha</label>
												</div>
												<div className="divFormInput mb-4">
													<Input
														id="confirmPasswordInput"
														name="confirmPassword"
														type="password"
														onChange={(e) => setConfirmPassword(e.target.value)}
														required
													/>
													<label className="text-muted">Confirme sua senha</label>
													{passwordError && <span className="spanError">{passwordError}</span>}
												</div>
											</Col>
										</Row>
										<div>
											<Button type="submit" className="w-100" color="main" disabled={registerButtonClicked}>
												{registerButtonClicked ? (
													<>
														Cadastrando <i className="fa fa-circle-notch fa-spin" />{' '}
													</>
												) : (
													<>Cadastrar</>
												)}
											</Button>
										</div>
									</form>
								)}
							</CardBody>
						</Card>
						<Row className="mt-3">
							<Col className="text-right"></Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default CreateAccountPage;
