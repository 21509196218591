import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import Cookie from 'js-cookie';
import axios from 'axios';
import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';

import { Context } from '../../../contexts/AuthContext';

import { providerSystem } from '../../../utils/providerAssets';

function ResetPasswordPage() {
	document.title = providerSystem()?.name + ' - Redefinir senha';

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [token, setToken] = useState('');

	const [verifyTokenResponse, setVerifyTokenResponse] = useState<any>({});
	const [resetPasswordPageLoading, setResetPasswordPageLoading] = useState(true);

	const [resetPasswordError, setResetPasswordError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const { setCompanies, setAuthenticated } = useContext(Context);

	const { key } = useParams<{ key: string }>();

	const history = useHistory();

	// Function go to Select Account Page with Companies
	const handleGoToSelectAccount = (companies: any) => {
		setCompanies(companies);
		history.push('/selectAccount');
	};

	// Set Authenticated before go to Dashboard
	const handleGoToDashboard = () => {
		window.location.href = '/';
		setAuthenticated(true);
	};

	// Handle Reset Password
	const handleResetPassword = () => {
		if (password != confirmPassword) {
			const passwordInput = document.getElementById('passwordInput');
			const confirmPasswordInput = document.getElementById('confirmPasswordInput');

			// Add error
			passwordInput?.classList.add('fieldError');
			confirmPasswordInput?.classList.add('fieldError');
			setPasswordError('As senhas não coincidem!');

			// Remove error
			passwordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			confirmPasswordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			return;
		}

		if (password.length < 6) {
			const passwordInput = document.getElementById('passwordInput');
			const confirmPasswordInput = document.getElementById('confirmPasswordInput');

			// Add error
			passwordInput?.classList.add('fieldError');
			confirmPasswordInput?.classList.add('fieldError');
			setPasswordError('A senha deve conter no mínimo 6 dígitos!');

			// Remove error
			passwordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			confirmPasswordInput?.addEventListener('click', () => {
				passwordInput?.classList.remove('fieldError');
				confirmPasswordInput?.classList.remove('fieldError');
				setPasswordError('');
			});

			return;
		}

		resetPasswordFunction(password, token);
	};

	// Reset Passoword Function
	const resetPasswordFunction = async (password: string, token: string) => {
		try {
			const response = await axios.post('/ts-api/auth/reset-password', {
				tokenId: token,
				password: password,
			});

			if (key != undefined) {
				setToken(key);
			}

			const { status, message } = response.data;

			switch (status) {
				case 'success':
					handleGoToDashboard();
					break;

				default:
					setResetPasswordError(message);
					break;
			}
		} catch (error: any) {}
	};

	// Get token verification
	useEffect(() => {
		const handleVerifyToken = async () => {
			try {
				const response = await axios.post('/ts-api/auth/verify-token-reset-password', {
					tokenId: key,
				});

				if (key != undefined) {
					setToken(key);
				}

				const { status, message } = response.data;

				switch (status) {
					case 'success':
						setVerifyTokenResponse(response.data);
						break;

					default:
						setVerifyTokenResponse(response.data);
						break;
				}
			} catch (error: any) {
			} finally {
				setResetPasswordPageLoading(false);
			}
		};

		handleVerifyToken();
	}, []);

	useEffect(() => {
		// Set Background Classlist
		document.body.classList.add('gray-100');
		document.body.classList.add('h-100');
	}, []);

	// If page loading
	if (resetPasswordPageLoading) {
		return (
			<Container className="bg-gray-100 h-100">
				<Row className="bg-gray-100 align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card>
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem() + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Redefinir senha</div>
								</div>

								<p className="font-weight-bold text-center mb--1" style={{ fontSize: '18px' }}>
									Carregando <i className="fa fa-circle-notch fa-spin" />
								</p>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<>
			<Container className="h-100">
				<Row className="align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card className="bg-white border-0 mb-0">
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem()?.assets + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Redefinir senha</div>
								</div>

								{verifyTokenResponse.status !== 'success' || resetPasswordError ? (
									// If token error
									<form>
										<Row>
											<Col lg="12" sm="12">
												<div className="divFormInput">
													<p className="text-muted font-weight-bold text-center">{verifyTokenResponse.message}</p>
												</div>
											</Col>
										</Row>
									</form>
								) : (
									// If token is valid
									<form
										onSubmit={(event) => {
											event.preventDefault();
										}}>
										<p className="text-muted font-weight-bold text-center mb-4">Olá, {verifyTokenResponse.user.name}</p>
										<div className="divFormInput mb-4">
											<Input
												id="passwordInput"
												name="password"
												type="password"
												onChange={(e) => setPassword(e.target.value)}
												required
											/>
											<label className="text-muted">Digite sua nova senha</label>
											{passwordError && <span className="spanError">{passwordError}</span>}
										</div>
										<div className="divFormInput mb-4">
											<Input
												id="confirmPasswordInput"
												name="repeatPassword"
												type="password"
												onChange={(e) => setConfirmPassword(e.target.value)}
												required
											/>
											<label className="text-muted">Repita a nova senha</label>
											{passwordError && <span className="spanError">{passwordError}</span>}
										</div>
										<div>
											<Button type="submit" className="w-100" color="main" onClick={handleResetPassword}>
												Redefinir
											</Button>
										</div>
									</form>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default ResetPasswordPage;
