export function providerSystem() {
	const favicon: any = document.getElementById('favicon');
	switch (window.location.hostname) {
		case 'app.whatsflow.com.br':
			favicon.href = window.location.origin + '/assets/provider/whatsflow/' + 'favincon/favicon.ico';
			return {
				name: 'Whatsflow',
				assets: window.location.origin + '/assets/provider/whatsflow/',
			};

		case 'zap.voicecoder.com.br':
			favicon.href = window.location.origin + '/assets/provider/voicecoder/' + 'favincon/favicon.ico';
			return {
				name: 'Voicecoder',
				assets: window.location.origin + '/assets/provider/voicecoder/',
			};

		case 'chat.clint.digital':
			favicon.href = window.location.origin + '/assets/provider/chat.clint.digital/' + 'favincon/favicon.ico';
			return {
				name: 'Clint Digital',
				assets: window.location.origin + '/assets/provider/chat.clint.digital/',
			};

		case 'app.xzapdigital.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.xzapdigital.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'XZAP DIGITAL',
				assets: window.location.origin + '/assets/provider/app.xzapdigital.com.br/',
			};

		case 'app.agilichat.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.agilichat.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'Agilichat',
				assets: window.location.origin + '/assets/provider/app.agilichat.com.br/',
			};

		case 'app.cebrac.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.cebrac.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'Cebrac',
				assets: window.location.origin + '/assets/provider/app.cebrac.com.br/',
			};

		case 'app.kingsconchat.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.kingsconchat.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'Kingsconchat',
				assets: window.location.origin + '/assets/provider/app.kingsconchat.com.br/',
			};

		case 'app.vendere.cc':
			favicon.href = window.location.origin + '/assets/provider/app.vendere.cc/' + 'favincon/favicon.ico';
			return {
				name: 'Vendere',
				assets: window.location.origin + '/assets/provider/app.vendere.cc/',
			};

		case 'app.stheelsolucoes.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.stheelsolucoes.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'Stheel Solucoes',
				assets: window.location.origin + '/assets/provider/app.stheelsolucoes.com.br/',
			};

		case 'app.botflux.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.botflux.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'bot flux',
				assets: window.location.origin + '/assets/provider/app.botflux.com.br/',
			};
		case 'app.big8chat.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.big8chat.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'big8chat',
				assets: window.location.origin + '/assets/provider/app.big8chat.com.br/',
			};
		case 'whitelabel.whatsflow.com.br':
			favicon.href = window.location.origin + '/assets/provider/whitelabel.whatsflow.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'whitelabel',
				assets: window.location.origin + '/assets/provider/whitelabel.whatsflow.com.br/',
			};
		case 'app.msnova.ai':
			favicon.href = window.location.origin + '/assets/provider/app.msnova.ai/' + 'favincon/favicon.ico';
			return {
				name: 'msnova',
				assets: window.location.origin + '/assets/provider/app.msnova.ai/',
			};
		case 'app.sendhit.com.br':
			favicon.href = window.location.origin + '/assets/provider/app.sendhit.com.br/' + 'favincon/favicon.ico';
			return {
				name: 'sendhit',
				assets: window.location.origin + '/assets/provider/app.sendhit.com.br/',
			};
		case 'web.chaty.digital':
			favicon.href = window.location.origin + '/assets/provider/web.chaty.digital/' + 'favincon/favicon.ico';
			return {
				name: 'chaty.digital',
				assets: window.location.origin + '/assets/provider/web.chaty.digital/',
			};
		default:
			favicon.href = window.location.origin + '/assets/provider/whatsflow/' + 'favincon/favicon.ico';
			return {
				name: 'Whatsflow',
				assets: window.location.origin + '/assets/provider/whatsflow/',
			};
	}
}
