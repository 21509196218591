import { createContext, useState } from 'react';

interface ModalContextProps {
	openState: boolean;
	setOpenState: any;
	savedForRefresh: any;
	setSavedForRefresh: any;
}

export const ModalContext = createContext({} as ModalContextProps);

export function ModalProvider({ children }: any) {
	const [openState, setOpenState] = useState(false);
	const [savedForRefresh, setSavedForRefresh] = useState(0);

	return <ModalContext.Provider value={{ openState, setOpenState, savedForRefresh, setSavedForRefresh }}>{children}</ModalContext.Provider>;
}
