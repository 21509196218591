import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Cookie from 'js-cookie';
import axios from 'axios';

import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';

import { errorNotification } from '../../../utils/notifications';

import { Context } from '../../../contexts/AuthContext';

import { providerSystem } from '../../../utils/providerAssets';

import './styles.scss';

function LoginPage() {
	document.title = providerSystem()?.name + ' - Login';

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [buttonLoginClicked, setButtonLoginClicked] = useState(false);

	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const history = useHistory();

	const { setAuthenticated, setCompanies, setAuth } = useContext(Context);

	// Handle Login
	const handleLogin = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		setButtonLoginClicked(true);
		await login(email, password);
	};

	// Function go to Select Account Page with Companies
	const handleGoToSelectAccount = (companies: any, auth: any) => {
		setCompanies(companies);
		setAuth(auth);

		history.push('/selectAccount');
	};

	// Set Authenticated before go to Dashboard
	const handleGoToDashboard = (token: string) => {
		setAuthenticated(true);
		Cookie.set('jwtAuth', token, { expires: 30, secure: true, sameSite: 'None' });

		window.location.href = '/';
	};

	// Login Function
	const login = async (email: string, password: string) => {
		try {
			const response = await axios.post('/ts-api/auth/login', {
				email: email,
				password: password,
			});

			const { status, message, companies, user, tokenJWT } = response.data;

			switch (status) {
				case 'success':
					if (companies.length > 1) {
						handleGoToSelectAccount(companies, {
							email: email,
							password: password,
						});
					} else if (companies.length === 1) {
						// Set userName into localStorage
						const userInformations = {
							userName: user.name,
							companyName: companies[0].name,
						};

						localStorage.setItem('userInformations', JSON.stringify(userInformations));

						handleGoToDashboard(tokenJWT);
					}
					break;

				case 'emailOrPassInvalid':
					setEmailError(message);
					setPasswordError(message);
					setButtonLoginClicked(false);
					break;

				default:
					errorNotification('', message);
					setButtonLoginClicked(false);
					break;
			}
		} catch (error: any) {
			errorNotification('', error.message);
			setButtonLoginClicked(false);
		}
	};

	// Set Background Classlist
	useEffect(() => {
		document.body.classList.add('gray-100');
		document.body.classList.add('h-100');
	}, []);

	// Set Email Input error
	useEffect(() => {
		const emailInput = document.getElementById('emailInput');

		// Add error
		if (!!emailError) {
			emailInput?.classList.add('fieldError');
		}

		// Remove error
		emailInput?.addEventListener('click', () => {
			emailInput.classList.remove('fieldError');
			setEmailError('');
		});
	}, [emailError]);

	// Set Password Input error
	useEffect(() => {
		const passwordInput = document.getElementById('passwordInput');

		// Add error
		if (!!passwordError) {
			passwordInput?.classList.add('fieldError');
		}

		// Remove error
		passwordInput?.addEventListener('click', () => {
			passwordInput.classList.remove('fieldError');
			setPasswordError('');
		});
	}, [passwordError]);

	useEffect(() => {
		if (window.location.hostname === 'chat.clint.digital') {
			const script = document.createElement('script');

			script.src = 'https://file.clint.digital/whatsapp/content.js';
			script.async = true;

			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, []);

	return (
		<>
			<Container className="h-100">
				<Row className="align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card className="bg-white border-0 mb-0">
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem()?.assets + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Fazer login</div>
								</div>

								<div className="mb-4">
									{emailError && <span className="text-center text-danger font-weight-bold lh-df">{emailError}</span>}
								</div>

								<form onSubmit={handleLogin} autoComplete="off">
									<div className="divFormInput mb-4">
										<Input
											id="emailInput"
											name="email"
											type="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											required
											autoComplete="off"
										/>
										<label className="text-muted">E-mail</label>
									</div>
									<div className="divFormInput mb-4">
										<Input
											id="passwordInput"
											name="password"
											type="password"
											value={password || ''}
											onChange={(e) => setPassword(e.target.value)}
											required
											autoComplete="off"
										/>
										<label className="text-muted">Senha</label>
										<div className="d-flex align-items-center justify-content-between w-100 mt-2">
											<Link to="/forgotPassword">
												<small className="font-weight-bold text-main">Esqueceu a senha?</small>
											</Link>
										</div>
									</div>
									<div>
										<Button type="submit" className="w-100" color="main" disabled={buttonLoginClicked}>
											{buttonLoginClicked ? (
												// If button is clicked, disable until response
												<>
													Entrando <i className="fa fa-circle-notch fa-spin" />
												</>
											) : (
												<>Entrar</>
											)}
										</Button>
									</div>

									<div className={providerSystem()?.name != 'Whatsflow' ? 'd-none' : 'mt-4'}>
										<a href="https://whatsflow.com.br" target="_blank">
											<Button type="button" className="w-100" color="main" outline>
												Criar nova conta
											</Button>
										</a>
									</div>
								</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default LoginPage;
