import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';

import { providerSystem } from '../../../utils/providerAssets';

function ForgotPasswordPage() {
	document.title = providerSystem()?.name + ' Esqueci minha senha';

	const [email, setEmail] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const [text, setText] = useState('');
	const [buttonSendEmailClicked, setButtonSendEmailClicked] = useState(false);

	const [emailError, setEmailError] = useState('');

	// Set Email Request Success ( set text form and Email sent )
	const handleSuccess = (message: React.SetStateAction<string>) => {
		setText(message);
		setEmailSent(true);
	};

	// Set Email Request Fail ( set Email error and button clicked false )
	const setEmailRequestFail = (message: React.SetStateAction<string>) => {
		setEmailError(message);
		setButtonSendEmailClicked(false);
	};

	// Handle Forgot Password
	const handleForgotPassword = async () => {
		setButtonSendEmailClicked(true);
		try {
			const response = await axios.post('/ts-api/auth/forgot-password', {
				email: email.toLocaleLowerCase(),
			});

			const { status, message } = response.data;

			switch (status) {
				case 'success':
					handleSuccess(`Email enviado com sucesso para (${email.toLocaleLowerCase()}), aguarde até 5 minutos.`);
					break;

				default:
					setEmailRequestFail(message);
					setButtonSendEmailClicked(false);
					break;
			}
		} catch (error: any) {
			setButtonSendEmailClicked(false);
		}
	};

	// Set Email Input error
	useEffect(() => {
		const emailInput = document.getElementById('emailInput');

		// Add error
		if (emailError) {
			emailInput?.classList.add('fieldError');
		}

		// Remove error
		emailInput?.addEventListener('click', () => {
			emailInput.classList.remove('fieldError');
			setEmailError('');
		});
	}, [emailError]);

	// Set Background Classlist
	useEffect(() => {
		document.body.classList.add('gray-100');
		document.body.classList.add('h-100');
	}, []);

	return (
		<>
			<Container className="h-100">
				<Row className="align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card className="bg-white border-0 mb-0">
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem()?.assets + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df">Esqueci minha senha</div>
								</div>

								{emailSent ? (
									<p className="text-muted font-weight-bold text-center">{text}</p>
								) : (
									<form
										onSubmit={(event) => {
											event.preventDefault();
										}}>
										<div className="divFormInput mb-4">
											<Input id="emailInput" name="email" type="email" onChange={(e) => setEmail(e.target.value)} required />
											<label className="text-muted">E-mail</label>
											{emailError && <span className="spanError">{emailError}</span>}
										</div>
										<div>
											<Button
												type="submit"
												className="w-100"
												color="main"
												disabled={buttonSendEmailClicked}
												onClick={handleForgotPassword}>
												{buttonSendEmailClicked ? (
													<>
														Enviando email <i className="fa fa-circle-notch fa-spin" />
													</>
												) : (
													<>Enviar</>
												)}
											</Button>

											<Link to="/">
												<small className="font-weight-bold text-main">Voltar ao login</small>
											</Link>
										</div>
									</form>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default ForgotPasswordPage;
