import React, { useEffect, useContext, useState } from 'react';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router';
import axios from 'axios';

import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';

import { Context } from '../../../contexts/AuthContext';

import { providerSystem } from '../../../utils/providerAssets';

function SelectAccountPage() {
	document.title = providerSystem()?.name + ' - Selecionar conta';

	const [selectAccountPageLoading, setSelectAccountPageLoading] = useState(false);
	const [indexButton, setIndexButton] = useState();

	const { companies, logout, auth } = useContext(Context);

	const history = useHistory();

	const handleSelectCompany = async (uuid: any, name: any, index: React.SetStateAction<undefined>) => {
		setSelectAccountPageLoading(true);
		setIndexButton(index);

		try {
			console.log(auth);

			const response = await axios.post('/ts-api/auth/login', {
				email: auth.email,
				password: auth.password,
				companyId: uuid,
			});

			const { status, message, companies, user, tokenJWT } = response.data;

			switch (status) {
				case 'fail':
					setSelectAccountPageLoading(false);
					break;
				case 'success':
					console.log(response.data);

					Cookie.set('jwtAuth', tokenJWT, { expires: 30, secure: true, sameSite: 'None' });

					// Set userName into localStorage
					const userInformations = {
						userName: user.name,
						companyName: companies[0].name,
					};

					localStorage.setItem('userInformations', JSON.stringify(userInformations));

					window.location.href = '/';
					break;
			}
		} catch (error: any) {
			setSelectAccountPageLoading(false);
		}
	};

	// If companies === 0, logout user
	useEffect(() => {
		if (companies.length === 0) {
			Cookie.remove('jwtAuth');
			Cookie.remove('userName');
			history.push('/');
		}
	}, []);

	useEffect(() => {
		document.body.classList.add('gray-100');
		document.body.classList.add('h-100');
	}, []);

	return (
		<>
			<Container className="h-100">
				<Row className="align-items-center justify-content-center h-100 h-max-860">
					<Col className="px-2 px-sm-0 px-xl-4" sm="10" md="7" lg="5">
						<Card className="bg-white border-0 mb-0">
							<CardBody>
								<div className="mb-4">
									<span className="d-inline-flex justify-content-center w-100 mb-2">
										<img src={providerSystem()?.assets + 'logo.png'} alt="" style={{ width: '170px' }} />
									</span>
									<div className="text-center text-muted font-weight-bold lh-df mb-2">Selecione uma conta</div>
								</div>

								<form
									onSubmit={(event) => {
										event.preventDefault();
									}}>
									{companies.map((company: { name: string; uuid: any }, index: any) => (
										<Button
											className="w-100 mb-2 ml-1"
											key={company.name}
											onClick={() => handleSelectCompany(company.uuid, company.name, index)}
											disabled={selectAccountPageLoading}>
											{index === indexButton && selectAccountPageLoading ? (
												<span className="font-weight-bold align-center">
													{company.name} <i className="fa fa-circle-notch fa-spin" />
												</span>
											) : (
												<>{company.name}</>
											)}
										</Button>
									))}

									<div className="separatorLogin">
										<hr />
										<span className="text-muted font-weight-bold">OU</span>
										<hr />
									</div>
									<div>
										<Button onClick={logout} className="w-100" color="main" outline>
											Logout
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default SelectAccountPage;
