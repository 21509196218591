import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import { AuthProvider } from './contexts/AuthContext';
import { ModalProvider } from './contexts/ModalContext';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'select2/dist/css/select2.min.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/scss/index.scss';

import 'antd/dist/antd.css';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter basename="/new">
			<AuthProvider>
				<ModalProvider>
					<App />
				</ModalProvider>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
