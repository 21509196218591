import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'reactstrap';
import Cookie from 'js-cookie';

import { Context } from './contexts/AuthContext';

import LoginPage from './views/Auth/LoginPage';
import CreateAccountPage from './views/Auth/CreateAccountPage';
import ForgotPasswordPage from './views/Auth/ForgotPasswordPage';
import ResetPasswordPage from './views/Auth/ResetPasswordPage';
import SelectAccountPage from './views/Auth/SelectAccountPage';

import './App.scss';

function App() {
	const { loading } = useContext(Context);

	const cookieToken = Cookie.get('jwtAuth');

	const history = useHistory();

	function CustomRoute({ isPrivate, ...rest }: any) {
		if (isPrivate && cookieToken) {
			return <Route {...rest} />;
		}

		return <Route {...rest} />;

		// Return to login
		// return <div>{history.push('/') as any}</div>
	}

	function CustomRouteAuth({ isPrivate, ...rest }: any) {
		if (isPrivate && cookieToken) {
			return (window.location.href = '/') as any;
			// return <Route {...rest} />
		}

		return <Route {...rest} />;
	}

	// Page is loading
	if (loading) {
		return (
			<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Spinner type="grow" color="primary" />
			</div>
		);
	}

	// api.defaults.headers.common['Authorization'] = `Bearer ${cookieToken}`;

	return (
		<>
			<Switch>
				<CustomRouteAuth exact isPrivate path="/" component={LoginPage} />
				<CustomRouteAuth isPrivate path="/signup/token/:key" component={CreateAccountPage} />
				<CustomRouteAuth isPrivate path="/forgotPassword" component={ForgotPasswordPage} />
				<CustomRouteAuth path="/selectAccount" component={SelectAccountPage} />
				<CustomRouteAuth isPrivate path="/resetPassword/token/:key" component={ResetPasswordPage} />
				<Redirect from="*" to="/" />
			</Switch>
			<ToastContainer position="top-right" autoClose={5000} />
		</>
	);
}

export default App;
